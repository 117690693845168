const theme = {
    primary_color: "#1a4677",
    secondary_color: "#fe80b2",
    tertiary_color: "#7e37d8",
    color4: "#5987af",
    color5: "#88aecb",
    light_color: "#DCE6EC",
    color6: "#59C4D6",
    color7: "#d85a37",
    color8: "#D83737",
};

export const RATINGS_DATA = [
    {
        x: "Excellent",
        y: 0,
    },
    {
        x: "Very Good",
        y: 0,
    },
    {
        x: "Good",
        y: 0,
    },
    {
        x: "Fair",
        y: 0,
    },
    {
        x: "Poor",
        y: 0,
    },
];

export const PROVENACE_DATA = [
    {
        x: "1006",
        y: 62,
    },
    {
        x: "1022",
        y: 104,
    },
    {
        x: "1023",
        y: 50,
    },
    {
        x: "1054",
        y: 40,
    },
    {
        x: "1240",
        y: 505,
    },
];

export const INDIA_DATA = [
    {
        x: "Jan",
        y: 800,
    },
    {
        x: "Feb",
        y: 300,
    },
    {
        x: "Mar",
        y: 900,
    },
    {
        x: "Apr",
        y: 100,
    },
];
export const US_DATA = [
    {
        x: "Jan",
        y: 50,
    },
    {
        x: "Feb",
        y: 10,
    },
    {
        x: "Mar",
        y: 40,
    },
    {
        x: "Apr",
        y: 150,
    },
];
export const AUS_DATA = [
    {
        x: "Jan",
        y: 80,
    },
    {
        x: "Feb",
        y: 20,
    },
    {
        x: "Mar",
        y: 50,
    },
    {
        x: "Apr",
        y: 40,
    },
];
export const GBR_DATA = [
    {
        x: "Jan",
        y: 10,
    },
    {
        x: "Feb",
        y: 30,
    },
    {
        x: "Mar",
        y: 50,
    },
    {
        x: "Apr",
        y: 2,
    },
];

export const FEEDBACKS_DATA = [
    {
        x: "Jan",
        y: 30,
    },
    {
        x: "Feb",
        y: 50,
    },
    {
        x: "Mar",
        y: 5,
    },
    {
        x: "Apr",
        y: 6,
    },
];

export const PRODUCTS_DATA = [
    {
        x: "Jan",
        y: 1,
    },
    {
        x: "Feb",
        y: 2,
    },
    {
        x: "Mar",
        y: 2,
    },
    {
        x: "Apr",
        y: 4,
    },
];

export const ENROLMENTS_DATA = [
    {
        x: "Jan",
        y: 40,
    },
    {
        x: "Feb",
        y: 27,
    },
    {
        x: "Mar",
        y: 12,
    },
    {
        x: "Apr",
        y: 17,
    },
];

export const DISPUTED_CRED_DATA = [
    {
        x: "Jan",
        y: 200,
    },
    {
        x: "Feb",
        y: 30,
    },
    {
        x: "Mar",
        y: 90,
    },
    {
        x: "Apr",
        y: 76,
    },
    {
        x: "May",
        y: 98,
    },
    {
        x: "Jun",
        y: 11,
    },
    {
        x: "July",
        y: 29,
    },
];

export const DATA = [
    {
        x: "Jan",
        y: 2300,
    },
    {
        x: "Feb",
        y: 2000,
    },
    {
        x: "Mar",
        y: 1000,
    },
    {
        x: "Apr",
        y: 218,
    },
];

export const TIMELINE_DATA = [
    {
        x: new Date("2021-01-01").getTime(),
        y: 100,
    },
    {
        x: new Date("2021-02-01").getTime(),
        y: 22,
    },
    {
        x: new Date("2021-03-01").getTime(),
        y: 35,
    },
    {
        x: new Date("2021-04-01").getTime(),
        y: 125,
    },
    {
        x: new Date("2021-05-01").getTime(),
        y: 180,
    },
    {
        x: new Date("2021-06-01").getTime(),
        y: 200,
    },
    {
        x: new Date("2021-07-01").getTime(),
        y: 58,
    },
    {
        x: new Date("2021-08-01").getTime(),
        y: 21,
    },
    {
        x: new Date("2021-09-01").getTime(),
        y: 98,
    },
    {
        x: new Date("2021-10-01").getTime(),
        y: 190,
    },
    {
        x: new Date("2021-11-01").getTime(),
        y: 200,
    },
    {
        x: new Date("2021-12-01").getTime(),
        y: 76,
    },
];

export const LAYOUT = [
    // {i: 'a', x: 0, y: 0, w: 1, h: 2, },
    { i: "empty-1", x: 0, y: 0, w: 1, h: 2 },
    { i: "l", x: 1, y: 0, w: 3, h: 2 },
    { i: "x", x: 4, y: 0, w: 3, h: 2 },
    { i: "o", x: 7, y: 0, w: 3, h: 2 },
    { i: "k", x: 1, y: 0.5, w: 3, h: 2 },
    { i: "m", x: 4, y: 0.5, w: 3, h: 2 },
    { i: "y", x: 7, y: 0.5, w: 3, h: 2 },
    { i: "empty-2", x: 11, y: 0, w: 1, h: 2 },
    { i: "n", x: 0, y: 1, w: 12, h: 12 },
    { i: "empty-1", x: 0, y: 3, w: 1.5, h: 3 },
    { i: "empty-2", x: 10.5, y: 3, w: 1.5, h: 3 },
    { i: "a", x: 0, y: 4, w: 6, h: 6 },
    { i: "b", x: 7, y: 4, w: 6, h: 6 },
    { i: "c", x: 0, y: 5, w: 6, h: 6 },
];

export const LAYOUTSM = [
    // {i: 'a', x: 0, y: 0, w: 1, h: 2, },
    { i: "empty-1", x: 0, y: 0, w: 1, h: 0 },
    { i: "k", x: 1, y: 0, w: 12, h: 2 },
    { i: "empty-1", x: 0, y: 0, w: 1, h: 0 },
    { i: "l", x: 1, y: 0, w: 12, h: 2 },
    { i: "empty-1", x: 0, y: 0, w: 1, h: 0 },
    { i: "m", x: 1, y: 0, w: 12, h: 2 },
    { i: "empty-1", x: 0, y: 0, w: 1, h: 0 },
    { i: "x", x: 1, y: 0, w: 12, h: 2 },
    { i: "empty-1", x: 0, y: 0, w: 1, h: 0 },
    { i: "y", x: 1, y: 0, w: 12, h: 2 },
    { i: "empty-2", x: 11, y: 0, w: 1, h: 0 },
    { i: "empty-1", x: 0, y: 3, w: 1.5, h: 3 },
    { i: "1", x: 1.5, y: 3, w: 3, h: 3 },
    { i: "2", x: 4.5, y: 3, w: 3, h: 3 },
    { i: "3", x: 7.5, y: 3, w: 3, h: 30 },
    { i: "empty-2", x: 10.5, y: 3, w: 1.5, h: 3 },
    { i: "a", x: 0, y: 4, w: 12, h: 6 },
    { i: "b", x: 0, y: 5, w: 12, h: 6 },
    { i: "c", x: 0, y: 6, w: 12, h: 6 },
    { i: "d", x: 0, y: 7, w: 12, h: 6 },
    { i: "e", x: 0, y: 8, w: 12, h: 6 },
    { i: "f", x: 0, y: 9, w: 12, h: 6 },
    { i: "g", x: 0, y: 10, w: 12, h: 5 },
    { i: "h", x: 0, y: 11, w: 12, h: 5 },
    { i: "i", x: 0, y: 3, w: 12, h: 6 },
    { i: "j", x: 0, y: 1, w: 12, h: 6 },
    { i: "n", x: 0, y: 2, w: 12, h: 6 },
];

export const GRID_ITEMS = [
    {
        key: "empty-1",
        backgroundColor: "transparent",
        type: "placeholder",
        typeProps: {
            backgroundColor: "transparent",
        },
    },
    {
        key: "empty-2",
        backgroundColor: "transparent",
        type: "placeholder",
        typeProps: {
            backgroundColor: "transparent",
        },
    },
    {
        key: "a",
        backgroundColor: "transparent",
        type: "line-chart",
        typeProps: {
            options: {
                chart: {
                    // background: 'transparent',
                    // foreColor: '#fff',
                },
                stroke: {
                    curve: "smooth",
                },
                colors: [theme.tertiary_color, theme.secondary_color],
                markers: {
                    size: 5,
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 90, 100],
                    }, //'gradient' / 'solid' / 'pattern' / 'image'
                },
                xaxis: {
                    type: "category",
                },
            },
            series: [
                {
                    name: "Scans",
                    data: DATA,
                },
            ],
            headerProps: {
                title: "Valid Scans",
                titleType: "title3",
                titleStyle: {
                    color: "#000",
                    fontWeight: "bold",
                },
            },
            cardStyle: {},
            cardHeaderStyle: {
                backgroundColor: "#d2d2d2",
            },
            footerProps: {
                cardFooterStyle: {
                    backgroundColor: "transparent",
                },
                title: "Number of Scans over months",
                titleType: "footnote",
                titleStyle: {
                    textAlign: "right",
                },
            },
        },
    },
    {
        key: "b",
        backgroundColor: "transparent",
        type: "line-chart",
        typeProps: {
            options: {
                chart: {
                    background: "transparent",
                    // foreColor: '#fff',
                },
                stroke: {
                    curve: "straight",
                },
                colors: [theme.secondary_color],
                markers: {
                    size: 5,
                },
                fill: {
                    type: "gradient", //'gradient' / 'solid' / 'pattern' / 'image'
                },
                xaxis: {
                    type: "category",
                },
            },
            series: [
                {
                    name: "Scans",
                    data: DATA,
                },
            ],
            headerProps: {
                title: "Brand Protection Scans",
                titleType: "title3",
                titleStyle: {
                    color: "#000",
                    fontWeight: "bold",
                },
            },
            cardStyle: {},
            cardHeaderStyle: {
                backgroundColor: "#d2d2d2",
            },
            footerProps: {
                cardFooterStyle: {
                    backgroundColor: "transparent",
                },
                title: "Number of Scans over months",
                titleType: "footnote",
                titleStyle: {
                    textAlign: "right",
                },
            },
        },
    },
    {
        key: "c",
        backgroundColor: "transparent",
        type: "column-chart",
        typeProps: {
            options: {
                chart: {
                    background: "transparent",
                    // foreColor: '#fff',
                },
                colors: [
                    theme.color7,
                    theme.secondary_color,
                    theme.tertiary_color,
                ],
                markers: {
                    size: 0,
                },
                fill: {
                    type: "solid",
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 90, 100],
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        // columnWidth: '55%',
                        endingShape: "rounded",
                    },
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        colors: ["#000"],
                    },
                    offsetX: 30,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                },
                xaxis: {
                    type: "category",
                },
            },
            series: [
                {
                    name: "Ratings",
                    data: RATINGS_DATA,
                },
            ],
            headerProps: {
                title: "Ratings",
                titleType: "title3",
                titleStyle: {
                    color: "#000",
                    fontWeight: "bold",
                },
            },
            cardStyle: {},
            cardHeaderStyle: {
                backgroundColor: "#d2d2d2",
            },
            footerProps: {
                cardFooterStyle: {
                    backgroundColor: "transparent",
                },
                title: "Ratings category",
                titleType: "footnote",
                titleStyle: {
                    textAlign: "right",
                },
            },
        },
    },
    {
        key: "k",
        type: "card-icon",
        typeProps: {
            cardStyle: {
                //backgroundImage: `linear-gradient(180deg, ${theme.primary_color}, ${theme.secondary_color})`
                backgroundColor: theme.tertiary_color,
                // borderRadius: 50,
            },
            backgroundIconProps: {
                name: "barcode-scan",
                type: "material-community",
                color: "#fff",
                size: 100,
            },
            foregroundIconProps: {
                name: "barcode-scan",
                type: "material-community",
                color: "#fff",
                size: 50,
            },
            contentProps: {
                title: {
                    title: "Total Scans",
                    titleStyle: {
                        color: "#fff",
                    },
                },
                subtitle: {
                    title: "",
                    titleStyle: {
                        color: "#fff",
                    },
                },
            },
        },
    },
    {
        key: "l",
        type: "card-icon",
        typeProps: {
            cardStyle: {
                //backgroundImage: `linear-gradient(180deg, ${theme.primary_color}, ${theme.secondary_color})`
                backgroundColor: theme.secondary_color,
                // borderRadius: 50,
            },
            backgroundIconProps: {
                name: "md-wine",
                type: "ionicon",
                color: "#fff",
                size: 100,
            },
            foregroundIconProps: {
                name: "bottle-soda",
                type: "material-community",
                color: "#fff",
                size: 50,
            },
            contentProps: {
                title: {
                    title: "Total Products Onboarded",
                    titleStyle: {
                        color: "#fff",
                    },
                },
                subtitle: {
                    title: "",
                    titleStyle: {
                        color: "#fff",
                    },
                },
            },
        },
    },
    {
        key: "m",
        type: "card-icon",
        typeProps: {
            cardStyle: {
                //backgroundImage: `linear-gradient(180deg, ${theme.primary_color}, ${theme.secondary_color})`
                backgroundColor: theme.color6,
                // borderRadius: 50,
            },
            backgroundIconProps: {
                name: "feedback",
                type: "material",
                color: "#fff",
                size: 100,
            },
            foregroundIconProps: {
                name: "star-half",
                type: "material",
                color: "#fff",
                size: 50,
            },
            contentProps: {
                title: {
                    title: "Total Feedbacks",
                    titleStyle: {
                        color: "#fff",
                    },
                },
                subtitle: {
                    title: "",
                    titleStyle: {
                        color: "#fff",
                    },
                },
            },
        },
    },
    {
        key: "x",
        type: "card-icon",
        typeProps: {
            cardStyle: {
                //backgroundImage: `linear-gradient(180deg, ${theme.primary_color}, ${theme.secondary_color})`
                backgroundColor: theme.color4,
                // borderRadius: 50,
            },
            backgroundIconProps: {
                name: "tag-multiple",
                type: "material-community",
                color: "#fff",
                size: 100,
            },
            foregroundIconProps: {
                name: "tag-multiple",
                type: "material-community",
                color: "#fff",
                size: 50,
            },
            contentProps: {
                title: {
                    title: "Total Barcodes onboarded",
                    titleStyle: {
                        color: "#fff",
                    },
                },
                subtitle: {
                    title: "",
                    titleStyle: {
                        color: "#fff",
                    },
                },
            },
        },
    },
    {
        key: "y",
        type: "card-icon",
        typeProps: {
            cardStyle: {
                //backgroundImage: `linear-gradient(180deg, ${theme.primary_color}, ${theme.secondary_color})`
                backgroundColor: theme.color8,
                // borderRadius: 50,
            },
            backgroundIconProps: {
                name: "person",
                type: "ionIcons",
                color: "#fff",
                size: 100,
            },
            foregroundIconProps: {
                name: "person",
                type: "ionIcons",
                color: "#fff",
                size: 50,
            },
            contentProps: {
                title: {
                    title: "Number of Dashboard Users",
                    titleStyle: {
                        color: "#fff",
                    },
                },
                subtitle: {
                    title: "",
                    titleStyle: {
                        color: "#fff",
                    },
                },
            },
        },
    },
    {
        key: "o",
        type: "card-icon",
        typeProps: {
            cardStyle: {
                //backgroundImage: `linear-gradient(180deg, ${theme.primary_color}, ${theme.secondary_color})`
                backgroundColor: theme.color7,
                // borderRadius: 50,
            },
            backgroundIconProps: {
                name: "stack-exchange",
                type: "font-awesome",
                color: "#fff",
                size: 100,
            },
            foregroundIconProps: {
                name: "stack-exchange",
                type: "font-awesome",
                color: "#fff",
                size: 50,
            },
            contentProps: {
                title: {
                    title: "Total Provenances Available",
                    titleStyle: {
                        color: "#fff",
                    },
                },
                subtitle: {
                    title: "",
                    titleStyle: {
                        color: "#fff",
                    },
                },
            },
        },
    },

    {
        key: "n",
        backgroundColor: "transparent",
        type: "chropleth",
        typeProps: {
            data: [
                { id: "AP", state: "Andhra Pradesh", value: 12 },
                { id: "AR", state: "Arunachal Pradesh", value: 13 },
                { id: "AS", state: "Assam", value: 14 },
                { id: "BR", state: "Bihar", value: 15 },
                { id: "CT", state: "Chhattisgarh", value: 55 },
                { id: "GA", state: "Goa", value: 21 },
                { id: "GJ", state: "Gujarat", value: 22 },
                { id: "HR", state: "Haryana", value: 44 },
                { id: "HP", state: "Himachal Pradesh", value: 24 },
                { id: "JH", state: "Jharkhand", value: 26 },
                { id: "KA", state: "Karnataka", value: 20000 },
                { id: "KL", state: "Kerala", value: 77 },
                { id: "MP", state: "Madhya Pradesh", value: 88 },
                { id: "MH", state: "Maharashtra", value: 85 },
                { id: "MN", state: "Manipur", value: 33 },
                { id: "ML", state: "Meghalaya", value: 59 },
                { id: "MZ", state: "Mizoram", value: 23 },
                { id: "NL", state: "Nagaland", value: 59 },
                { id: "OR", state: "Odisha", value: 59 },
                { id: "PB", state: "Punjab", value: 23 },
                { id: "RJ", state: "Rajasthan", value: 34 },
                { id: "SK", state: "Sikkim", value: 23 },
                { id: "TN", state: "Tamil Nadu", value: 45 },
                { id: "TG", state: "Telangana", value: 45 },
                { id: "TR", state: "Tripura", value: 14 },
                { id: "UT", state: "Uttarakhand", value: 78 },
                { id: "UP", state: "Uttar Pradesh", value: 15 },
                { id: "WB", state: "West Bengal", value: 17 },
                { id: "WB", state: "West Bengal", value: 17 },
                { id: "AN", state: "Andaman and Nicobar Islands", value: 23 },
                { id: "CH", state: "Chandigarh", value: 53 },
                { id: "DN", state: "Dadra and Nagar Haveli", value: 19 },
                { id: "DD", state: "Daman and Diu", value: 20 },
                { id: "DL", state: "Delhi", value: 59 },
                { id: "JK", state: "Jammu and Kashmir", value: 25 },
                { id: "LA", state: "Ladakh", value: 12 },
                { id: "LD", state: "Lakshadweep", value: 43 },
                { id: "PY", state: "Puducherry", value: 12 },
            ],
            headerProps: {
                title: "Number of Scans",
                titleType: "title3",
                titleStyle: {
                    color: "#000",
                    fontWeight: "bold",
                },
            },
            cardStyle: {},
            cardHeaderStyle: {
                backgroundColor: "#d2d2d2",
            },
            footerProps: {
                cardFooterStyle: {
                    backgroundColor: "transparent",
                },
                title: "Number of Scans across states",
                titleType: "footnote",
                titleStyle: {
                    textAlign: "right",
                },
            },
        },
    },
];
